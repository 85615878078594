



























































































import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";

// Despite being in analytics, we're using the other endpoints since none of this is in Elastic, so we don't need new endpoints.
import GeneralCommentsModule from "@/store/vuex-modules/GeneralComments";
const commentsStore = getModule(GeneralCommentsModule);

import optionsVuexModule from "@/store/vuex-modules/options";
const optionsStore = getModule(optionsVuexModule);

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import Spinners from "@/components/utilities/Spinners.vue";
import AnalyticsDateControl from "./AnalyticsDateControl.vue";

import { transformDataToCsvUrl } from "@/services/csv";

import { ObjectParams } from "@/store/vuex-modules/GeneralComments";
import {
    GeneralCommentsObjectContent,
    QuestionOfTheDay
} from "@/types/interfaces";

export default Vue.extend({
    components: {
        Spinners,
        AnalyticsDateControl
    },
    data() {
        return {
            loading: false,
            commentsData: [] as Array<GeneralCommentsObjectContent>
        };
    },
    computed: {
        questionId(): string {
            return this.$route.params.questionId;
        },

        tableHeaders(): Array<string> {
            return ["Attendee Id", "Attendee Name", "Date", "Response"];
        },

        tableFooter(): Array<string> {
            return ["Total Responses", "", "", `${this.totalHits}`];
        },

        totalHits(): number {
            return this.commentsData.length;
        },

        csvUrl(): string {
            return this.getCsvUrl();
        },

        questions(): Array<QuestionOfTheDay> | undefined {
            return optionsStore.options.questionsOfTheDay;
        },

        thisQuestion(): QuestionOfTheDay | undefined {
            return (
                this.questions &&
                this.questions.find(
                    (question) => question.id == this.questionId
                )
            );
        },

        flatData(): Array<Array<string>> {
            return this.commentsData.map((comment) => {
                return [
                    comment.attendeeId ? comment.attendeeId : "",
                    comment.attendeeName ? comment.attendeeName : "",
                    comment.createdTime ? comment.createdTime : "",
                    comment.text ? comment.text : ""
                ];
            });
        },

        commentsDate(): string {
            return analyticsStore.reportDates.start
                ? `${analyticsStore.reportDates.start}T00:00:00.000Z`
                : "";
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(): Promise<void> {
            this.loading = true;

            this.commentsData.splice(0);

            await optionsStore.getOptions(["questionsOfTheDay"]);

            const start = this.commentsDate
                ? this.commentsDate
                : "1900-01-01T00:00:00.000Z";

            const commentsParams = {
                type: "Question",
                id: this.questionId,
                loadFrom: start
            } as ObjectParams;

            commentsStore
                .getComments(commentsParams)
                .then((res) => {
                    const x = res as Array<GeneralCommentsObjectContent>;
                    x.forEach((item) => {
                        this.commentsData.push(item);
                    });
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getCsvUrl(): string {
            const dataArray = [
                this.tableHeaders,
                ...this.flatData,
                this.tableFooter
            ];

            return transformDataToCsvUrl(dataArray);
        }
    }
});
