





















































































import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";

// Despite being in analytics, we're using the other endpoints since none of this is in Elastic, so we don't need new endpoints.
import GeneralCommentsModule from "@/store/vuex-modules/GeneralComments";
const commentsStore = getModule(GeneralCommentsModule);

import optionsVuexModule from "@/store/vuex-modules/options";
const optionsStore = getModule(optionsVuexModule);

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import Spinners from "@/components/utilities/Spinners.vue";
import AnalyticsDateControl from "./AnalyticsDateControl.vue";

import { transformDataToCsvUrl } from "@/services/csv";

import { ObjectParams } from "@/store/vuex-modules/GeneralComments";
import {
    GeneralCommentsObjectContent,
    QuestionOfTheDay
} from "@/types/interfaces";

export default Vue.extend({
    components: {
        Spinners,
        AnalyticsDateControl
    },
    data() {
        return {
            loading: false,
            commentsData: [] as Array<GeneralCommentsObjectContent>
        };
    },
    computed: {
        tableHeaders(): Array<string> {
            return ["Question ID", "Question", "Date", "Responses"];
        },

        tableFooter(): Array<string> {
            return ["Total", "", "", `${this.totalHits}`];
        },

        totalHits(): number {
            return this.commentsData.length;
        },

        csvUrl(): string {
            return this.getCsvUrl();
        },

        questions(): Array<QuestionOfTheDay> {
            return optionsStore.options.questionsOfTheDay
                ? optionsStore.options.questionsOfTheDay
                : [];
        },

        flatData(): Array<Array<string>> {
            const returnArr = [] as Array<Array<string>>;

            this.questions.forEach((question: QuestionOfTheDay) => {
                const totalForQuestion = this.commentsData.filter(
                    (data) => data.relatedObjectId === question.id
                ).length;
                returnArr.push([
                    question.id,
                    question.text,
                    question.date.toString(),
                    `${totalForQuestion}`
                ]);
            });

            return returnArr;
        },

        commentsDate(): string {
            return analyticsStore.reportDates.start
                ? `${analyticsStore.reportDates.start}T00:00:00.000Z`
                : "";
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(): Promise<void> {
            this.loading = true;

            this.commentsData.splice(0);

            await optionsStore.getOptions(["questionsOfTheDay"]);

            const start = this.commentsDate
                ? this.commentsDate
                : "1900-01-01T00:00:00.000Z";

            const allParams = {
                type: "Question",
                loadFrom: start
            } as Partial<ObjectParams>;

            const promises = [] as Array<Promise<string>>;

            this.questions.forEach((question: QuestionOfTheDay) => {
                promises.push(
                    new Promise((resolve, reject) => {
                        const commentsParams = {
                            ...allParams,
                            id: question.id
                        } as ObjectParams;

                        commentsStore
                            .getComments(commentsParams)
                            .then((res) => {
                                const x = res as Array<
                                    GeneralCommentsObjectContent
                                >;
                                x.forEach((item) => {
                                    this.commentsData.push(item);
                                });
                                resolve("done");
                            })
                            .catch((error) => {
                                reject(error);
                                console.error(error);
                            });
                    })
                );
            });

            Promise.allSettled(promises).then((result) => {
                this.loading = false;
            });
        },

        getCsvUrl(): string {
            const dataArray = [
                this.tableHeaders,
                ...this.flatData,
                this.tableFooter
            ];

            return transformDataToCsvUrl(dataArray);
        }
    }
});
